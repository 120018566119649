<template>
    <div>
        <div class="d-flex flex-wrap my-3">
            <div class="col-12 col-lg-10 text-center">
                <h1 class="h2">
                    Colaboradores en
                    {{ selectedMerchant.merchantType | merchantInfo("type") }}
                    <strong class="text-primary">
                        {{ selectedMerchant.name }}
                    </strong>
                </h1>
            </div>
            <div
                class="col-12 col-lg-2 d-flex align-items-center justify-content-center"
            >
                <vs-button
                    circle
                    icon
                    floating
                    @click="listMerchantsUserComponent"
                >
                    <reload-icon />
                </vs-button>
            </div>
        </div>

        <div>
            <span>
                En esta sección, podrás visualizar la lista de colaboradores que
                tienen acceso a tu comercio. Si necesitas agregar más personas
                para que realicen operaciones en él, puedes invitarlas desde
                aquí mismo.
            </span>
        </div>

        <div v-if="isLoading" class="d-flex justify-content-center">
            <span class="my-3"> Cargando... </span>
        </div>

        <div v-else>
            <users-roles-manage
                v-model="users"
                :enabled="$ability.hasScope('user:UpdateOwn')"
                :default-filters="{ type: 'merchant' }"
            />
            <div class="d-flex flex-wrap mt-4">
                <div
                    v-if="$ability.hasScope('user:UpdateOwn')"
                    class="col-12 d-flex justify-content-center"
                >
                    <vs-button @click="updateUsersMerchantComponent">
                        Actualizar usuarios
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import UsersRolesManage from "@/components/users/UsersRolesManage.vue";

export default {
    name: "UsersDetails",
    components: {
        UsersRolesManage,
        ReloadIcon
    },
    data: () => ({
        users: [],
        isLoading: false,
        loaderInstance: null
    }),
    computed: {
        ...mapState("commons", ["selectedMerchant"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        selectedMerchant() {
            this.listMerchantsUserComponent();
        }
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("user:ListOwn", "/app/stores", true)))
            return;
        this.listMerchantsUserComponent();
    },
    methods: {
        ...mapActions("users", ["listUsersMerchant", "updateUsersMerchant"]),
        async listMerchantsUserComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                this.users = await this.listUsersMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateUsersMerchantComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateUsersMerchant({
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType,
                    users: this.users.map((item) => ({
                        email: item.email,
                        roleSlug: item.role.slug
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.isLoading = false;
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        }
    }
};
</script>
